.filter-dropdown {
  position: relative;

  [filterButton] {
    display: flex;
    align-items: center;
  }

  .filter-icons {
    font-size: 24px;
    margin-right: 8px;
  }

  .modal-dropdown {
    color: black;
    width: 447px;
    border: solid 1px var(--color-grey-light);
    box-shadow: 0px 10px 20px rgba(236, 236, 236, 0.5);
    border-radius: 10px;
    position: absolute;
    top: 54px;
    left: 0;
    background-color: white;
    padding: 25px;
    cursor: auto;
    -webkit-transform:translate3d(0,0,0);
    z-index: 1002;

    @media (max-width: 900px) {
      border: none;
      width: 100%;
      top: 70px;
      position: fixed;
    }

    @media (max-width: 414px) {
      height: calc(100% - 70px) !important;
      max-height: 100%;
    }

    &-text {
      display: flex;
      justify-content: flex-start;
      line-height: 32px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.01em;
      margin-bottom: 12px;
    }

    &-footer {
      border-top: 1px solid var(--color-grey-light);
      background-color: white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 0 0 0;
    }
  }

  .search-btn-modal {
    border: 1px solid var(--color-grey-su-super-dark);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0px 37px;
    height: 48px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.01em;
    font-size: 16px;
  }

  .cancel-btn {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }

  .scrollInModal {
    height: 310px;
    overflow: scroll;
  }

  .active-red-btn,
  .unactive-red-btn {
    padding: 0px 14px;
    border-radius: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;

    input[type="radio"] {
      display: none;
    }
  }

  .active-red-btn {
    background-color: var(--color-red-dark);
    border: 1px solid transparent;
    color: white;
  }

  .unactive-red-btn {
    background-color: white;
    border: 1px solid var(--color-grey-medium);
    color: var(--color-grey-medium);
  }

  label.checkbox {
    display: flex;
    margin-bottom: 12px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    text-transform: capitalize;

    span.icon-check {
      font-size: 18px;
      margin-right: 10px;
    }

    span.text {
      font-size: 16px;
      line-height: 19px;
      font-weight: normal;
    }
  }

  .filterTypePlus {
    background: white;
    border-radius: 100px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 25px;
    margin-left: 8px;
    color: var(--color-grey-super-dark);
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.04em;
  }

  .form-inputs .input-label label {
    top: 15px;
  }
}
