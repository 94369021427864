import { RouterModule, Route } from '@angular/router';
import { NgModule } from '@angular/core';

import { MainRoutes } from '@/constants';
import { CustomRoutes } from '@/models';
import { authGuard } from '@/guards';

import { NotFoundComponent } from '@/modules/core/components/not-found/not-found.component';
import { StatementAllComponent } from '@/modules/shared/components/statement-all/statement-all.component';
import { MetiersComponent } from '@/modules/shared/components/metiers/metiers.component';
import { LegalNoticeComponent } from '@/modules/shared/components/legal-notice/legal-notice.component';
import { agenceMatcher, landingMatcher, routeToPath } from '@/utilities/routes-matchers';

/**
 * Routes permatently redirected
 */
const permanentRedirections: Route[] = Object.entries({
  'jobparty': '/emploi-immobilier',
  'vendre': '/estimation',
  'agence-immobiliere-pouilly-sous-charlieu-387': '/agence-immobiliere-roanne-168',
  'agence-immobiliere-charlieu-388': '/agence-immobiliere-roanne-168',
  'groupes/era-pierre-perchey-immobilier-48': '/agence-immobiliere-roanne-168',
  'agence-immobiliere-paris-16-369': '/agence-immobiliere-paris-16-16-1346',
  'agence-immobiliere-avignon-306': '/agence-immobiliere-carpentras-536',
  'agence-immobiliere-avignon-48': '/agence-immobiliere-roanne-168'
}).map(([path, redirectTo]) => ({
  path,
  redirectTo,
  pathMatch: 'full'
}));

const annoncesMetaData = {
  achat: {
    title: 'LANDING.ACHAT.COUNTRY.title',
    description: 'LANDING.ACHAT.COUNTRY.description',
    ogTitle: 'LANDING.ACHAT.COUNTRY.title',
    ogDescription: 'LANDING.ACHAT.COUNTRY.description',
    ogUrl: 'LANDING.ACHAT.COUNTRY.ogUrl'
  },
  location: {
    title: 'LANDING.LOCATION.COUNTRY.title',
    description: 'LANDING.LOCATION.COUNTRY.description',
    ogTitle: 'LANDING.LOCATION.COUNTRY.title',
    ogDescription: 'LANDING.LOCATION.COUNTRY.description',
    ogUrl: 'LANDING.LOCATION.COUNTRY.ogUrl'
  }
} as const;

const routes: CustomRoutes = [{
  path: '',
  pathMatch: 'full',
  loadChildren: () => import('@/modules/features/home-page/home-page.module'),
  data: {
    zoomLogo: true
  }
}, {
  path: routeToPath(MainRoutes.Acheter),
  loadChildren: () => import('@/modules/features/annonces/annonces.module'),
  data: {
    ...annoncesMetaData,
    searchMode: 'annonces',
    hideFooter: true,
    getAgence: true,
    type: 'acheter'
  }
}, {
  path: routeToPath(MainRoutes.Louer),
  loadChildren: () => import('@/modules/features/annonces/annonces.module'),
  data: {
    ...annoncesMetaData,
    searchMode: 'annonces',
    hideFooter: true,
    getAgence: true,
    type: 'louer'
  }
}, {
  path: routeToPath(MainRoutes.Annonce),
  loadChildren: () => import('@/modules/features/annonce/annonce.module'),
  data: {
    getAgence: true
  }
}, {
  path: routeToPath(MainRoutes.Agences),
  loadChildren: () => import('@/modules/features/agences/agences.module'),
  data: {
    searchMode: 'agences',
    hideFooter: true
  }
}, {
  path: routeToPath(MainRoutes.Estimation),
  loadChildren: () => import('@/modules/features/estimation/estimation.module'),
  data: {
    hideHeaderShadow: true,
    getAgence: true
  }
}, {
  path: routeToPath(MainRoutes.Estimer),
  loadChildren: () => import('@/modules/features/estimer/estimer.module'),
  data: {
    hideHeaderShadow: true,
    hideFooter: true,
    getAgence: true
  }
}, {
  path: routeToPath(MainRoutes.Contact),
  loadChildren: () => import('@/modules/features/contact/contact.module'),
  data: {
    hideFooter: true
  }
}, {
  path: routeToPath(MainRoutes.Groupes),
  loadChildren: () => import('@/modules/features/groupes/groupes.module'),
  data: {
    headerType: 'agence'
  }
}, {
  path: routeToPath(MainRoutes.Effectifs),
  loadChildren: () => import('@/modules/features/effectifs/effectifs.module')
}, {
  path: routeToPath(MainRoutes.Recrutement),
  loadChildren: () => import('@/modules/features/recrutement/recrutement.module'),
  data: {
    hideHeaderShadow: true
  }
}, {
  path: routeToPath(MainRoutes.OffreEmploi),
  loadChildren: () => import('@/modules/features/offre-emploi/offre-emploi.module')
}, {
  path: routeToPath(MainRoutes.Faqs),
  loadChildren: () => import('@/modules/features/faqs/faqs.module')
}, {
  path: routeToPath(MainRoutes.SitePlan),
  loadChildren: () => import('@/modules/features/site-plan/site-plan.module')
}, {
  path: routeToPath(MainRoutes.PersonalData),
  loadChildren: () => import('@/modules/features/personal-data/personal-data.module')
}, {
  path: routeToPath(MainRoutes.Favoris),
  loadChildren: () => import('@/modules/features/favoris/favoris.module')
}, {
  path: routeToPath(MainRoutes.Guide),
  loadChildren: () => import('@/modules/features/guide/guide.module'),
  data: {
    title: 'GUIDE.APP.title',
    description: 'GUIDE.APP.description',
    ogTitle: 'GUIDE.APP.title',
    ogDescription: 'GUIDE.APP.description'
  }
}, {
  path: routeToPath(MainRoutes.Blog),
  loadChildren: () => import('@/modules/features/blog/blog.module')
}, {
  path: routeToPath(MainRoutes.Metiers),
  loadChildren: () => import('@/modules/features/content/content.module')
}, {
  path: routeToPath(MainRoutes.Avis),
  component: StatementAllComponent,
  data: {
    title: 'AVIS.APP.title',
    description: 'AVIS.APP.description'
  }
}, {
  path: routeToPath(MainRoutes.MentionsLegales),
  component: LegalNoticeComponent
}, {
  path: routeToPath(MainRoutes.GestionLocative),
  component: MetiersComponent,
  data: {
    metier: 'gestion locative'
  }
}, {
  path: routeToPath(MainRoutes.Syndic),
  component: MetiersComponent,
  data: {
    metier: 'gestion de syndic'
  }
}, {
  path: routeToPath(MainRoutes.LocauxCommerciaux),
  component: MetiersComponent,
  data: {
    metier: 'locaux commerciaux'
  }
}, {
  path: routeToPath(MainRoutes.ResetPassword),
  loadChildren: () => import('@/modules/features/home-page/home-page.module'),
  data: {
    openModal: 'reset-password'
  }
}, {
  path: routeToPath(MainRoutes.Profile),
  loadChildren: () => import('@/modules/features/account/account.module'),
  canMatch: [authGuard()],
  data: {
    title: 'PROFILE.APP.title',
    description: 'PROFILE.APP.description',
    ogTitle: 'PROFILE.APP.title',
    ogDescription: 'PROFILE.APP.description'
  }
},
...permanentRedirections,
{
  path: routeToPath(MainRoutes.NotFound),
  component: NotFoundComponent
}, {
  matcher: agenceMatcher,
  loadChildren: () => import('@/modules/features/agence/agence.module'),
  data: {
    headerType: 'agence'
  }
}, {
  matcher: landingMatcher,
  loadChildren: () => import('@/modules/features/landing/landing.module'),
  data: {
    ...annoncesMetaData
  }
}, {
  path: '**',
  component: NotFoundComponent
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      enableTracing: false
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
