import { isDevMode } from '@angular/core';
import { TranslocoOptions } from '@jsverse/transloco';

import { TranslocoHttpLoader } from './transloco-loader';

export const translocoConfig: TranslocoOptions = {
  loader: TranslocoHttpLoader,
  config: {
    availableLangs: ['fr'],
    defaultLang: 'fr',
    reRenderOnLangChange: false,
    prodMode: !isDevMode()
  }
};
