app-location-filter {
	.input-location {
		width: 100%;

		input {
			width: 100%;
			height: 48px;
			border-radius: 100px;
		}

		.input-icon {
			background-color: var(--color-red-dark);
			position: absolute;
			cursor: pointer;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 40px;
			width: 40px;
			bottom: 4px;
			right: 4px;
		}
	}

	.dropdown {
		position: absolute;
		top: 55px;
		background: #ffffff;
		box-shadow: 0px 0px 20px 0px var(--box-shadow);
		border-radius: 10px;
		padding: 15px 30px;
		z-index: 5;

		@media (max-width: 500px) {
			height: 200px;
			overflow-x: scroll;
		}

		@media (max-width: 360px) {
			padding: 10px;
		}

		.cities, .departments {
			margin-bottom: 20px;

			.header-title {
				font-weight: 500;
				font-size: 16px;
				line-height: 32px;
				letter-spacing: 0.01em;
				text-transform: uppercase;
				color: #898989;
			}

			.suggest span {
				font-weight: bold;
				font-size: 18px;
				line-height: 21px;
				display: flex;
				align-items: center;

				&:last-of-type {
					letter-spacing: 0.02em;
				}
			}
		}
	}

	.tag {
		padding: 0px 8px;
		color: var(--color-blue-dark);
		border: 1px solid var(--color-blue-dark);
		margin-right: 10px;
		display: inline-block;
		border-radius: 5px;
		margin-bottom: 12px;

		.close {
			color: #898989;
			cursor: pointer;
			vertical-align: middle;
		}
	}

	.slider-container {
		display: flex;
		align-items: center;
		margin: 15px 0;

		p {
			white-space: nowrap;
			font-weight: 600;
			width: 50px;
			text-align: right;

			span {
				font-weight: normal;
			}
		}
	}

	.geoloc-button {
		position: absolute;
		background: var(--color-grey-white);
		padding: 5px;
		top: 50%;
		height: 40px;
		width: 40px;
		box-shadow: 0px 0px 20px 0px var(--box-shadow);
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		left: 5px;
		cursor: pointer;
		transform: translateY(-50%);
		z-index: 1;
	}
}
