import { HttpRequest, HttpErrorResponse, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { inject } from '@angular/core';

import { ToastService } from '@/modules/toast/services/toast.service';
import { HeaderService } from '@/services/header.service';
import { ConfigService } from '@/services/config.service';
import { AuthService } from '@/services/auth.service';
import { MainRoutes } from '@/constants';

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const headerService = inject(HeaderService);
  const configService = inject(ConfigService);
  const toastService = inject(ToastService);
  const authService = inject(AuthService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const { status } = error;

      if (configService.isBrowser) {
        if (status === 401) {
          authService.logout();
          headerService.showLoginModal(true, router.getCurrentNavigation()?.extractedUrl);
        } else if (status === 404) {
          router.navigate([MainRoutes.NotFound]);
        } else if (status > 401 && status < 500) {
          toastService.show({
            title: 'ERRORS.HTTP',
            body: 'ERRORS.4xx',
            translate: 'all',
            type: 'error'
          });
        } else if (status >= 500) {
          toastService.show({
            title: 'ERRORS.HTTP',
            body: 'ERRORS.5xx',
            translate: 'all',
            type: 'error'
          });
        }
      }

      return throwError(() => error);
    })
  );
};
