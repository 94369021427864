import { ApplicationRef, ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2, signal } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TranslocoService } from '@jsverse/transloco';
import { DOCUMENT } from '@angular/common';
import { first } from 'rxjs';

import { RouteObserverService } from '@/services/route-observer.service';
import { ConfigService } from '@/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent implements OnInit {
  public hideFooter = signal(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private routeObserver: RouteObserverService,
    private gtmService: GoogleTagManagerService,
    private applicationRef: ApplicationRef,
    private configService: ConfigService,
    private translate: TranslocoService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setLanguage();

    this.routeObserver.currentRoute$.subscribe(({ url, data }) => {
      this.hideFooter.set(!!data.hideFooter);

      this.gtmService.pushTag({
        'event': 'Pageview',
        'pageUrl': url
      });
    });

    if (this.configService.isBrowser && !!this.configService.config.externalScripts) {
      this.applicationRef.isStable.pipe(
        first((isStable) => isStable)
      ).subscribe(() => {
        console.debug('Loading external scripts');
        this.addScriptRecaptcha();
      });
    }
  }

  private setLanguage(): void {
    // Only one language for the moment
    this.translate.setActiveLang('fr');
  }

  private addScriptRecaptcha(): void {
    const script = this.renderer.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.configService.config.recaptcha}`;
    this.renderer.appendChild(this.document.body, script);

    const scriptClick = this.renderer.createElement('script');
    script.innerHTML = `
			function onClick(e) {
				e.preventDefault()
				grecaptcha.ready(function () {
					grecaptcha.execute(${this.configService.config.recaptcha}, { action: "validate_captcha" }).then(function (token) {
						document.getElementById("g-recaptcha-response").value = token
					})
				})
			}
    `;

    this.renderer.appendChild(this.document.body, scriptClick);
  }
}
