import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal, OnChanges, SimpleChanges, input } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, Observable, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

import { AnnoncesService } from '@/services/annonces.service';
import { AgencesService } from '@/services/agences.service';
import { FilterService } from '@/services/filter.service';
import { ToolsService } from '@/services/tools.service';
import { ANNONCE_STATE } from '@/constants';

import {
  FormBudgetFilters, FormCriteresFilters, FormLocationFilters, FormPiecesFilters,
  FormSurfaceFilters, FormFilters, FormTypeFilters, SearchFilters,
  SearchMode, ApiGeoMetaResponse
} from '@/models';

type AnyFormFilter =
  FormBudgetFilters | FormCriteresFilters | FormLocationFilters | FormPiecesFilters |
  FormSurfaceFilters | FormTypeFilters;

const FORM_VALUES_CHANGES_DEBOUNCE_TIME = 200;

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FiltersComponent implements OnInit, OnDestroy, OnChanges {
  readonly queryParams = input<Record<string, number>>({});
  readonly searchMode = input.required<SearchMode>();
  readonly currentItemsCount = input(0);

  private subscriptions = new Subscription();

  public filters = signal<SearchFilters>({});
  public totalItems = signal<number>(0);

  public form = new FormGroup({
    budget: new FormControl<FormBudgetFilters>({}),
    criteres: new FormControl<FormCriteresFilters>({}),
    location: new FormControl<FormLocationFilters>({}),
    pieces: new FormControl<FormPiecesFilters>({}),
    surface: new FormControl<FormSurfaceFilters>({}),
    type: new FormControl<FormTypeFilters>({})
  });

  constructor(
    private annoncesService: AnnoncesService,
    private agencesService: AgencesService,
    private filterService: FilterService,
    private toolsService: ToolsService
  ) { }

  ngOnInit(): void {
    // Listen to global filters changes
    this.subscriptions.add(
      this.filterService.filters$.pipe(
        filter(() => this.searchMode() === 'annonces'),
        distinctUntilChanged((prev, curr) => this.toolsService.deepEqual(prev, curr))
      ).subscribe((filters) => {
        this.filters.set(this.toolsService.deepCopy(filters));
        this.filtersToForm(this.filters());
      })
    );

    this.subscriptions.add(
      this.filterService.agencesFilters$.pipe(
        filter(() => this.searchMode() === 'agences'),
        distinctUntilChanged((prev, curr) => this.toolsService.deepEqual(prev, curr))
      ).subscribe((filters) => {
        this.filters.set(this.toolsService.deepCopy(filters));
        this.filtersToForm(this.filters());
      })
    );

    // Listen to form values changes
    this.subscriptions.add(
      this.form.valueChanges.pipe(
        debounceTime(FORM_VALUES_CHANGES_DEBOUNCE_TIME)
      ).subscribe((values) => {
        const filters = this.formToFilters(values as FormFilters);
        if (this.filterService.filtersChanged(this.filters(), filters)) {
          this.filters.set(filters);
          this.requestItems();
        };
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Inputs has changed. Update either items count (corresponding to active filters)
   * or search mode (annonces or agences)
   * @param changes The changed inputs
   */
  ngOnChanges(changes: SimpleChanges): void {
    const { currentItemsCount, searchMode } = changes;

    if (currentItemsCount && (currentItemsCount.currentValue !== currentItemsCount.previousValue)) {
      this.totalItems.set(this.currentItemsCount());
    }

    if (searchMode && (searchMode.currentValue !== searchMode.previousValue)) {
      const filters = (this.searchMode() === 'annonces') ? this.filterService.filters : this.filterService.agencesFilters;
      this.filters.set(this.toolsService.deepCopy(filters));
      this.filtersToForm(this.filters());
    }
  }

  /**
   * Navigate with the current active filters
   */
  handleValidate(): void {
    const newFilters = { ...this.filterService.filters, ...this.filters() };
    this.filterService.buildRouteFromFilters({ mode: this.searchMode() }, newFilters);
  }

  /**
   * A reset action has been clicked on one filter dropdown. Reset the impacted filters and validate.
   * @param values The filters values that must be overrided.
   */
  handleReset(values: AnyFormFilter): void {
    this.filters.update((current) => ({ ...current, ...values }));
    this.filtersToForm(this.filters());
    this.handleValidate();
  }

  /**
   * Send a request to the server using current filter values, just to retrieve
   * the count of items corresponding to these criterias.
   */
  private requestItems() {
    let request: Observable<ApiGeoMetaResponse<any>>;

    if (this.searchMode() === 'annonces') {
      const { agence_id, groupe_id } = this.queryParams();
      const id = groupe_id ?? agence_id;

      request = this.annoncesService.getAnnonces(
        1, undefined, ANNONCE_STATE, this.filters(), true, id, !!groupe_id
      );
    } else {
      request = this.agencesService.getAgences(1, this.filters());
    }

    this.subscriptions.add(
      request.subscribe(({ meta: { total } }) => {
        this.totalItems.set(total || 0);
      })
    );
  }

  /**
   * Fill form fields with the newly received SearchFilters object
   * @param filters The source filters
   */
  private filtersToForm(filters: SearchFilters): void {

    this.form.get('location')?.setValue({
      searchLocations: filters.searchLocations ?? [],
      distance: filters.distance ?? '',
      polygon: filters.polygon ?? ''
    });

    this.form.get('budget')?.setValue({
      prix_from: filters.prix_from ?? '',
      prix_to: filters.prix_to ?? ''
    });

    this.form.get('criteres')?.setValue({
      criteres: filters.criteres ?? []
    });

    this.form.get('pieces')?.setValue({
      nb_chambres: filters.nb_chambres ?? '',
      nb_pieces: filters.nb_pieces ?? ''
    });

    this.form.get('surface')?.setValue({
      surface_from: filters.surface_from ?? '',
      surface_to: filters.surface_to ?? '',
      terrain_from: filters.terrain_from ?? '',
      terrain_to: filters.terrain_to ?? ''
    });

    this.form.get('type')?.setValue({
      type_annonce: filters.type_annonce ?? 'vente',
      typeBien: filters.typeBien ?? []
    });
  }

  /**
   * Build a SearchFilters object from current form fields values
   * @param values The form values
   * @returns A SearchFilters object
   */
  private formToFilters(values: FormFilters): SearchFilters {
    const filters: SearchFilters = { ...this.filters() };

    filters.searchLocations = values.location.searchLocations;
    filters.distance = values.location.distance;
    filters.polygon = values.location.polygon;

    filters.prix_from = values.budget.prix_from;
    filters.prix_to = values.budget.prix_to;

    filters.criteres = values.criteres.criteres;

    filters.nb_chambres = values.pieces.nb_chambres;
    filters.nb_pieces = values.pieces.nb_pieces;

    filters.surface_from = values.surface.surface_from;
    filters.surface_to = values.surface.surface_to;
    filters.terrain_from = values.surface.terrain_from;
    filters.terrain_to = values.surface.terrain_to;

    filters.type_annonce = values.type.type_annonce;
    filters.typeBien = values.type.typeBien;

    filters.page = 1;

    return filters;
  }
}
