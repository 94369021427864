@use "styles/constants";

app-header {
	background-color: var(--white);
  position: sticky;
  display: flex;
  flex-direction: column;
	z-index: 100;
  width: 100%;
  top: 0;

	.header {
		&.header-shadow {
			box-shadow: 0px 10px 20px rgba(236, 236, 236, 0.5);
		}

		nav:first-of-type {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: constants.$header-height;
			padding: 0 15px;
		}
	}

	.header-logo {
		cursor: pointer;

		@media (max-width: 365px) {
			display: none;
		}
	}

	.active-alert {
		margin-left: auto;

		@media (max-width: 1000px) {
			.text-transition {
				transform: none;
			}

			.icon-notification {
				display: none;
			}
		}

		&.disable-button {
			background-color: var(--color-grey-medium);
			border: 1px solid var(--color-grey-medium);
			cursor: default;

			&:hover {
				.tooltip-alert-button {
					display: block;
				}
			}
		}
	}

	.tooltip-alert-button {
		display: none;
		width: 300px;
		background: black;
		color: white;
		position: absolute;
		padding: 15px;
		top: 61px;
		right: -39px;
		border-radius: 5px;
		z-index: 20;

		p {
			font-weight: normal;
		}

		&:before {
			content: "";
			position: absolute;
			right: 50%;
			bottom: 100%;
			width: 0;
			height: 0;
			border-top: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid black;
			border-left: 10px solid transparent;
			margin-right: -32px;
		}
	}

	.filter-search {
		display: flex;
		background-color: white;
		align-items: center;
		padding-left: 40px;
		padding-right: 40px;
		height: constants.$filters-height;
		width: 100%;
		box-shadow: 0px 10px 20px rgba(236, 236, 236, 0.5);
		z-index: 51;
		overflow-x: clip;
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}

		@media (max-width: 900px) {
			overflow-x: scroll;
			padding-left: 12px;
			padding-right: 12px;
		}

		&.hidden {
			display: none;
		}
	}

	.display-mobile {
		display: none !important;

		@media (max-width: 820px) {
			display: flex !important;
		}
	}

	.display-desktop {
		display: none !important;

		@media (min-width: 821px) {
			display: flex !important;
		}
	}

	.button-header {
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--white);
		border-radius: 50%;
		box-shadow: 0px 0px 20px var(--box-shadow);

		&.flat {
			@media (min-width: 821px) {
				border-color: transparent !important;
				box-shadow: none !important;

				+ span {
					margin-left: 5px;
				}
			}
		}

		&.locator:hover img {
			filter: invert(10%) sepia(80%) saturate(4000%) hue-rotate(290deg) brightness(90%) contrast(90%);
		}
	}
}
